<template lang="pug">
component(
    :is="data.type"
    :id="data.id"
    :title="data.title"
    :singleInput="data.singleInput"
    :options="options"
    :placeholder="data.placeholder"
    :value="data.value"
    @filterUpdate="(e,preventAdd,firstLoad,addThis,multi) => {this.$emit('update', {e,options: this.options, preventAdd, firstLoad, addThis, multi})}"
    )
</template>

<script>
import {mapGetters} from "vuex";
import DefaultSelect from "./DefaultSelect";
import DefaultSlider from "./DefaultSlider";
import DefaultMultiSelect from "./DefaultMultiSelect";
import DefaultDatePickers from "./DefaultDatePickers";

export default {
    name: "BaseWidget",
    components: {
        DSelect: DefaultSelect,
        DSlider: DefaultSlider,
        DMultiSelect: DefaultMultiSelect,
        DDatePickers: DefaultDatePickers
    },
    props: {
        data: {
            type: Object,
            default()  {
                return {}
            }
        }
    },
    computed: {
        ...mapGetters({
            selectedFilters: "posts/selectedFilters",
            filtersBreadcrumbs: "posts/filtersBreadcrumbs",
            tags: "posts/tags",
            eye_color: "posts/eye_color",
            hair_color: "posts/hair_color",
            skin_color: "posts/skin_color",
            gender: "posts/gender",
            date: "posts/date",
            age: "posts/age",
            height: "posts/height",
            weight: "posts/weight",
            characteristics: "posts/characteristics"
        }),
        options() {
            return this[this.data.id]
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
