<template lang="pug">
.default-date-picker
    h3 {{title}}
    .date-pickers(:class="[singleInput && 'single-input']")
        input(type="date" v-model="modelValue[0]" )
        input(v-if="!singleInput" type="date" v-model="modelValue[1]" )
</template>

<script>
export default {
    name: "DefaultDatePickers",
    emits: ["filterUpdate"],
    props: {
        options: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        singleInput: {
            type: Boolean,
            default: false
        },
        firstDate: {
            type: String,
            default: null
        },
        secondDate: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            modelValue: []
        }
    },
    mounted() {
        this.filterUpdateByQuery()
        this.emitter.on("filterUpdated", () => {
            this.filterUpdateByQuery(true)
        })
    },
    watch: {
        modelValue: {
            handler: function(val) {
                if(val[0] !== null || val[1] !== null) {
                    this.filterUpdate()
                }
            },
            deep: true
        },
        value(val) {
            if (val && val.length) {
                this.modelValue[0] = val
            }
        },
        firstDate(val) {
            if(val && val.length) {
                this.modelValue[0] = this.firstDate
            }
        },
        secondDate(val) {
            if(val && val.length) {
                this.modelValue[1] = this.secondDate
            }
        }
    },
    methods: {
        filterUpdate() {
            this.$emit("filterUpdate", { value: this.modelValue, title: `${this.title}: ${this.modelValue[0]}-${this.modelValue[1]}`, filterId: this.id, }, false, false, true)
        },
        filterUpdateByQuery() {
            const query = {...this.$route.query}
            let start_date = query.start_date
            let end_date = query.end_date

            if(start_date || end_date) {
                this.modelValue = [start_date,end_date]
                this.$emit("filterUpdate", { value: this.modelValue, title: `${this.title}: ${this.modelValue[0]} / ${this.modelValue[1]}`, filterId: this.id, }, false, true, true)
            }
            else {
                this.modelValue = [null,null]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.default-date-picker {
    margin-bottom: 15px;

    h3 {
        text-align: left;
        font: normal normal bold 14px/16px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        text-transform: uppercase;
        opacity: 1;
    }

    .date-pickers {
        margin: 10px 0 15px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
    }

    .date-pickers.single-input {
        grid-template-columns: 1fr;
    }
}
</style>
