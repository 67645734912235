<template lang="pug">
.default-select
    h3 {{ title }}
    v-select.select(
        v-if="value !== undefined"
        :options="options"
        :placeholder="placeholder"
        :modelValue="value"
        @update:modelValue="filterUpdate"
        :reduce="option => option.id" label="title"
        :searchable="false"
        :clearable="false"
    )
    v-select.select(
        v-else
        :options="options"
        :placeholder="placeholder"
        v-model="modelValue"
        @update:modelValue="filterUpdate"
        :reduce="option => option.id" label="title"
        :searchable="false"
        :clearable="false"
    )
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "DefaultSelect",
    components: {
        vSelect
    },
    emits: ["filterUpdate"],
    props: {
        options: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        value: {
            type: Number,
            default: undefined
        }
    },
    data() {
        return {
            modelValue: null,
            enableOptionsWatch: true
        }
    },
    mounted() {
        this.filterUpdateByQuery()
        this.emitter.on("filterUpdated", () => {
            this.filterUpdateByQuery()
        })
    },
    methods: {
        filterUpdate(val) {
            this.$emit("filterUpdate", {
                selectedId: val,
                filterId: this.id,
                titleStart: `${this.title}: `
            }, val === null)
        },
        filterUpdateByQuery() {
            const query = {...this.$route.query}

            //id = gender
            let tempValue = query[this.id]
            tempValue = parseInt(tempValue)

            if (!isNaN(tempValue)) {
                const option = this.options.find(el => {
                    return el.id === tempValue
                })
                if (option) {
                    this.$emit("filterUpdate", {
                        selectedId: option.id,
                        filterId: this.id,
                        titleStart: `${this.title}: `
                    }, false, true)
                    this.modelValue = option.id
                }
            } else {
                this.modelValue = null
            }

            //  Disable Options Watch --- problem disable ---
            this.enableOptionsWatch = true
        }
    },
    watch: {
        options() {
            if (this.enableOptionsWatch) {
                this.filterUpdateByQuery()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.default-select {
    > h3 {
        text-align: left;
        font: normal normal bold 14px/16px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        text-transform: uppercase;
        opacity: 1;
    }
    .select {
        margin: 10px 0 15px;
    }
}
</style>
