<template lang="pug">
.default-multi-select
    h3 {{ title }}
    v-select.select(
        multiple
        :options="options"
        :placeholder="placeholder"
        v-model="modelValue"
        @option:selecting="filterUpdate"
        @option:deselecting="filterUpdate"
        :reduce="option => option.id"
        label="title"
        :searchable="false"
        :clearable="false"
    )
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "DefaultMultiSelect",
    components: {
        vSelect
    },
    emits: ["filterUpdate"],
    props: {
        options: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        value: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            modelValue: [],
            enableOptionsWatch: true
        }
    },
    mounted() {
        this.filterUpdateByQuery()
        this.emitter.on("filterUpdated", () => {
            this.filterUpdateByQuery(true)
        })
    },
    watch: {
        options() {
            if(this.enableOptionsWatch) {
                this.filterUpdateByQuery()
            }
        },
        value(val) {
            this.modelValue = val
        }
    },
    methods: {
        filterUpdate(val) {
            this.$emit("filterUpdate", {selectedId: val.id, filterId: this.id, titleStart: `${this.title}: `}, false, false, false, true)
        },
        filterUpdateByQuery(fromEmit=false) {
            const query = {...this.$route.query}

            let tempValues = query[this.id]

            if(typeof tempValues === 'string') {
                tempValues = [parseInt(tempValues)]
            }

            if(tempValues && tempValues.length && this.options && this.options.length) {
                const modelValue = fromEmit ? [] : [...this.modelValue]
                tempValues.forEach((val) => {
                    const tempValue = parseInt(val)
                    if( modelValue.findIndex((index) => index === tempValue) === -1 ) { // new value is not in selected
                        modelValue.push(tempValue)
                        this.$emit("filterUpdate", {
                            selectedId: tempValue,
                            filterId: this.id,
                            titleStart: `${this.title}: `
                        }, false, true, false, true)
                    } else if (fromEmit) { // new value is selected and function call from emit
                        modelValue.push(tempValue)
                    }
                })
                this.enableOptionsWatch = false
                this.modelValue = modelValue
            } else {
                this.modelValue = []
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.default-multi-select {
    h3 {
        text-align: left;
        font: normal normal bold 14px/16px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        text-transform: uppercase;
        opacity: 1;
    }

}
</style>
