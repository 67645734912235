<template lang="pug">
.default-slider
    h3 {{ title }}
    Slider.slider(v-model="value" @update:modelValue="filterUpdate" :min="options[0]" :max="options[1]")
</template>

<script>
import "@vueform/slider/themes/default.css"
import Slider from '@vueform/slider'

export default {
    name: "DefaultSlider",
    components: {
        Slider
    },
    emits: ["filterUpdate"],
    props: {
        options: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            value: this.options
        }
    },
    mounted() {
        this.filterUpdateByQuery()
        this.emitter.on("filterUpdated", () => {
            this.filterUpdateByQuery(true)
        })
    },
    methods: {
        filterUpdate() {
            this.$emit("filterUpdate", { value: this.value, title: `${this.title}: ${this.value[0]}-${this.value[1]}`, filterId: this.id, }, false, false, true)
        },
        filterUpdateByQuery(fromEmit=false) {
            const query = {...this.$route.query}
            let minValue, maxValue

            if(this.id === "age") {
                minValue = query.age_min
                maxValue = query.age_max
            }
            else if(this.id === "height") {
                minValue = query.height_min
                maxValue = query.height_max
            }
            else if(this.id === "weight") {
                minValue = query.weight_min
                maxValue = query.weight_max
            }

            // Parse Int
            minValue = parseInt(minValue)
            maxValue = parseInt(maxValue)
            // Check if Exists
            if(!isNaN(minValue) && !isNaN(maxValue)) {
                this.value = [minValue,maxValue]
                this.$emit("filterUpdate", { value: this.value, title: `${this.title}: ${this.value[0]}-${this.value[1]}`, filterId: this.id, }, false, true, true)
            } else if(fromEmit) {
                this.value = this.options
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.default-slider {
    margin-bottom: 20px;

    h3 {
        text-align: left;
        font: normal normal bold 14px/16px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 20px;
    }

    .slider {
        margin: 15px 0 30px;
        --slider-bg: #E0DDDD;
        --slider-handle-bg: #4354B4;
        --slider-handle-width: 13px;
        --slider-handle-height: 13px;
        --slider-connect-bg: #4354B4;
        --slider-tooltip-color: #6E6E6E;
        --slider-tooltip-font-size: 10px;
        --slider-tooltip-bg: transparent;
        --slider-tooltip-distance: -8px;
    }
}
</style>
